import React from 'react';

import '../../styles.css';
import SEOComponent from '../components/fields/seoField';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';

const TermsOfServiceTerms = () => {
	return (
		<div className='static-page termsOfService'>
			<CommonPageRenderer>
				<SEOComponent
					title='Terms of Service - Snakes & Ladders Plus'
					description='Terms Of Service A. General These are the Terms of Use (Terms) which form a legal and binding agreement between Cashgrail Private Limited (referred to as the “Company”, “Cashgrail”, “us”, “we” or “our”) and You i.e., the User. For the use of the Zupee application (hereinafter referred to as Cashgrail Platform, or Platform), and any [...]'
				/>
				<div className='page-content mainContainer'>
					<h2 class='page-heading align-center'>Terms of Service</h2>
					<h3 class='page-subheading'>A. General</h3>
					<p className='description'>
						These are the Terms of Use <b>(Terms)</b> which form a legal and binding agreement between Cashgrail Private
						Limited (referred to as the “<b>Company</b>”, “<b>Cashgrail</b>”, “<b>us”</b>, “<b>we</b>” or “<b>our</b>”)
						and You i.e., the User. For the use of the Zupee application (hereinafter referred to as
						<b>Cashgrail Platform</b>, or
						<b>Platform</b>), and any data, information, text, graphics, video, sound, pictures, and any other materials
						appearing, sent, uploaded, communicated, transmitted or otherwise made available via the above-mentioned
						Platform. The access and use by Users in games provided by Cashgrail on the Platform (<b>Services</b>) shall
						be bound by these Terms, and all other rules, regulations and terms of use (including
						<a class='sub-page-link' href='/privacy-policy/'>
							Privacy Policy
						</a>
						) referred to herein or provided by Cashgrail in relation to the Services.
					</p>
					<p className='description'>You agree and acknowledge that you have completely read and understood these Terms.</p>
					<p className='description'>
						We reserve the right to amend, modify, or update any of the Terms for using the Cashgrail Platform or Services
						at any time by publishing new Terms. Cashgrail may, at its sole discretion, also notify the User of any change
						or modification in these Terms, Privacy Policy, rules, regulations and terms of use referred to herein or
						provided by Cashgrail, by way of delivering notifications or updates either within the relevant mobile
						application being used or via SMS to the Users. The User may then exercise the options provided in such
						notification to indicate non-acceptance of the modified Terms, Privacy Policy, rules, regulations and terms of
						use referred to herein or provided by Cashgrail. If such options to indicate non-acceptance are not exercised
						by the User within the time frame prescribed in the notification, the User will be deemed to have accepted the
						modified Terms, Privacy Policy, rules, regulations and terms of use referred to herein or provided by
						Cashgrail. If the User does not accept the modified Terms, rules, regulations and terms of use referred to
						herein or provided by Cashgrail, we reserve the right to deny access to the Platform and Services to the User.
					</p>
					<p className='description'>
						Participation in the games on the Cashgrail Platform or viewing of certain content may be subject to further
						terms and conditions as may be notified by us from time to time on the Platform or otherwise. You should
						ensure that you have read and understood and agree to abide by the rules and regulations of the games
						available through the Cashgrail Platform.
					</p>
					<p className='description'>Cashgrail may, at its sole and absolute discretion and without any prior notice:</p>
					<ol class='privacy-page-ol'>
						<li>
							Move or remove any content, including without limitation games, game rules, etc., that is available or
							posted by a User on Cashgrail Platform;
						</li>
						<li>Discontinue promotional offers and discount codes, if any;</li>
						<li>Disable the Services or access to the Platform;</li>
						<li>Establish general practices and limits concerning use of Cashgrail Platform;</li>
						<li>
							Assign its rights and liabilities to all User accounts hereunder to any entity (post such assignment,
							intimation of such assignment shall be sent to all Users to their registered email ID’s or in-app
							notifications or by way of SMS to their registered mobile number)
						</li>
					</ol>
					<p className='description'>
						Your use of information on our Platform including results, fixture lists, statistics, data, etc. posted on our
						Platform is at your own risk. Other than as expressly set out in these Terms and to the extent permitted by
						law, we make no warranty as to the accuracy or reliability of the information contained on our Platform or in
						our publications (including, but not limited to, game rules or any content or information generated on our
						Platform by or on behalf of us, and any Third-Party Content on our Platform). We and our related entities,
						directors, officers and agents disclaim all liability and responsibility for any direct or indirect loss or
						damage which may be suffered by any User through relying on anything contained in or omitted from our
						Platform.
					</p>
					<h3 class='page-subheading'>B. Description of Services Offered</h3>
					<p className='description'>
						Cashgrail gives Users, in compliance with these Terms, a personal, royalty- free, non-assignable,
						non-sublicensable, non-transferable, non-exclusive, and revocable limited license to use the Platform in India
						and only until the User is bound by these Terms. This license is for the sole purpose of enabling the own
						personal private use by Users to play the games of skill as provided by Cashgrail as per these Terms and must
						not be used for any commercial purposes.
					</p>
					<p className='description'>
						The Services may include advertisements, which may be targeted to the content or information on the Services,
						queries made through the Services, or any other information. The types and extent of advertising and
						promotional activities by Cashgrail on the Services are subject to change.
					</p>
					<p className='description'>
						When access to games are included in the Services, the specific rules, scoring, controls and guidelines for
						each game can be found within the game itself. Such rules are an integral part of the Terms, which Users agree
						to and shall comply with such rules, scoring, controls and guidelines etc.
					</p>
					<p className='description'>
						Any charges levied on Users for accessing the Services, including but not limited to internet connection
						and/or mobile or data charges are solely the responsibility of such Users.
					</p>
					<h3 class='page-subheading'>C. User Registration</h3>
					<p className='description'>
						In order to access or fully utilize the Platform offered by Cashgrail, a User is required to register on the
						Platform. You are not authorised to access our Platform until you have been granted an Account (defined
						below).
					</p>
					<p className='description'>
						If you wish to become a registered User, you will need to create an account (<b>Account</b>) on our Platform.
						Each User is only permitted to open one Account. Cashgrail reserves the right to terminate or suspend any
						duplicate Accounts.
					</p>
					<p className='description'>
						In order to create an Account, Cashgrail may require you to complete a form and provide certain details,
						including those provided below, in accordance with the instructions on the Platform:
					</p>
					<ol class='privacy-page-ol' type='a'>
						<li>Full Name</li>
						<li>Team Name(s)</li>
						<li>E-mail address</li>
						<li>Password</li>
						<li>State of Residence</li>
						<li>Gender</li>
						<li>Date of birth</li>
						<li>Mobile Number</li>
						<li>Bank Account Number</li>
						<li>Permanent Account Number</li>
						<li>Official Government ID (for KYC checks)</li>
					</ol>
					<p className='description'>
						You can also register or log in through your Google e-mail address. You may also be required to provide your
						mobile number for verification purposes and verify such mobile number using a one-time passcode sent to your
						mobile number. Permission to open the account shall be determined by Cashgrail or its representatives, at
						their sole discretion. We may refuse to grant an Account to any person without giving a reason for such
						refusal. You agree and acknowledge that the decision of Cashgrail in this regard shall be final.
					</p>
					<p className='description'>
						Users who wish to withdraw cash Winnings from the Account will be required to have a mobile number and bank
						account or UPI ID. In case such you withdraw amount exceeding a predetermined threshold which shall be
						notified to you, you will also need to provide a valid Permanent Account Number (PAN). You must be over 18
						years of age and a resident of India to access and/or use our Platform and create an Account with us. By
						agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 (eighteen) years old;
						(b) you have not previously been suspended or blocked from using the Platform; (c) you are a resident of
						India, and (d) your registration and your use of the Platform in compliance with any and all applicable laws
						and regulations. We reserve the right to request proof of age and deploy screening and age- gating techniques
						at any stage to verify that minors are not using the Platform and we may exclude a person from accessing or
						using the Platform, if proof of age is not provided or if Cashgrail suspects that a person doing so is under
						the age of 18 years.
					</p>
					<p className='description'>
						You agree to provide true, accurate, current and complete information at the time of registration and at all
						other times. You agree to update and keep updated all information provided for the purposes of registration.
						You undertake that all information provided by you from time to time is true, accurate, current and complete
						to your personal knowledge.
					</p>
					<p className='description'>
						Upon grant of the Account by us, your Account will be created, and you will be able to use our Platform and
						play the game(s). You agree and acknowledge that such Account can be terminated by us for any reason deemed
						fit by Cashgrail and we shall notify you of such termination.
					</p>
					<p className='description'>
						You represent and warrant to us that, throughout the course of your usage of our Platform, you will not permit
						other persons to access or use your Account. If you permit other persons to access or use your Account, you do
						so at your own sole risk as to any consequences. You further agree and accept that you shall not access or use
						the Platform through the account of another User.
					</p>
					<p className='description'>
						Where Cashgrail conducts any customer identity checks, this neither implies that Cashgrail is statutorily
						required to conduct such checks nor liable for any inaccuracy in customer verification that cannot be directly
						attributable to Cashgrail’s gross negligence. You are responsible for completing your ‘know your customer’
						checks with the banks, financial institutions, card associations, and other payment system providers as
						defined under the Payment and Settlement Systems Act, 2007 (<b>Payments System Providers</b>), with whom
						Cashgrail has entered into an arrangement to facilitate payment transactions (i.e. to send or receive funds
						via the Platform) using the services of Payments System Providers. Cashgrail conducts such checks as a measure
						to reduce financial crime in its multiple forms, such as online fraud, money laundering, or the financing of
						terrorism. You may also be required to verify any credit card/bank account you use to deposit funds, and also
						any bank account you want to withdraw to ensure their integrity and verify their compliance with current
						regulations and applicable laws.
					</p>
					<p className='description'>
						By accepting these terms and conditions upon registration, you give consent to Cashgrail to disclose your
						personal information provided to us to a third-party agency to assist in verifying your identity. The
						third-party agency may prepare and provide Cashgrail with such an assessment and may use your personal
						information including the names, residential addresses and date of birth, financial information, etc. for the
						purposes of preparing such an assessment. Such processing by the third-party entity will be in accordance with
						our Privacy Policy and subject to any additional obligations imposed by applicable laws at the time being in
						force.
					</p>
					<p className='description'>
						You acknowledge that your participation in any game(s) available on the Platform is purely voluntary and at
						your sole discretion and risk.
					</p>
					<h3 class='page-subheading'>
						D. Skill Based games played on Cashgrail’s application do not qualify as Gambling and Lottery
					</h3>
					<p className='description'>
						Users must note that the games hosted on the Cashgrail Platform are games of skill. In compliance with Indian
						law, we do not support, endorse, or offer to Users, games of chance for money. While games of skill do not
						have a comprehensive definition, they are considered to be those games where the impact of a player’s effort
						and skill on the outcome of a game is higher than the impact of luck and chance.
					</p>
					<h3 class='page-subheading'>
						E. Platform not offered in Assam, Meghalaya, Odisha, Telangana, Nagaland, Andhra Pradesh, Arunachal Pradesh,
						Sikkim and outside India
					</h3>
					<p className='description'>
						Cashgrail may, in accordance with the laws prevailing in certain Indian states, bar individuals residing in
						those states from participating in the game(s). Currently, individuals residing in the Indian states of Assam,
						Meghalaya, Odisha, Telangana, Nagaland, Andhra Pradesh, Arunachal Pradesh, Sikkim and outside India are not
						allowed to participate in the paid version of the game(s) as the laws of these states bar persons from
						participating in games of skill where Users are required to pay to enter. Access to the Cashgrail Platform
						will therefore be restricted from being accessed from such states.
					</p>
					<p className='description'>
						You agree and acknowledge that we may monitor or use certain technologies and monitoring of activities
						including logging of your IP address to ascertain and verify your geographical location. As a final check, we
						may also seek additional personal details as proof of address during KYC approval.
					</p>
					<p className='description'>
						Cashgrail does not allow the Users residing in the aforesaid Indian states or outside the territory of India
						to play with real money or deposit amounts to play games on the Platform. Users agree and acknowledge that if
						a person residing in such Indian states, or from outside the territorial limits of the Republic of India
						becomes a User by giving false information, the liability shall be solely on the User and Cashgrail reserves
						the right to delete/remove such Account.
					</p>
					<p className='description'>
						You agree and accept that Cashgrail shall not be liable or responsible for the breach of applicable state laws
						by any User. There may be applicable penalties imposed on such Users by the respective State Governments or
						the Central Government of India as the case may be.
					</p>
					<p className='description'>
						Users from all Indian states are responsible for and must always ensure that they are complying with the
						applicable state laws and we will attempt to post any changes that we seem appropriate to better educate the
						Users. You agree and acknowledge that our decision in this regard will be final and binding, and no
						consideration, whatsoever, will be extended to the User.
					</p>
					<h3 class='page-subheading'>F. Security</h3>
					<p className='description'>
						Access to your Account and to add, delete or modify any content on your Account is OTP protected. You must
						ensure that the OTP is not accessible to any person apart from you. We are not liable for any loss, damages or
						expenses of any kind incurred or suffered by you arising from or in connection with any failure, delay or
						interruption in transmitting OTPs due to any failure of the mobile service network or any other circumstance
						beyond our reasonable control.
					</p>
					<p className='description'>
						You agree that you will not disclose your password/OTP to anyone or allow any other person to access or use
						your Account. We are not responsible if your Account is accessed or opened by other persons using your
						password/OTP. You shall be held solely responsible and liable for all of your activities on the Platform made
						from your Account.
					</p>
					<p className='description'>
						You understand that it is your obligation to protect the information you have provided on the Platform
						including, but not limited, to usernames, email addresses, contact details, bank account information etc. You
						explicitly understand and agree that we will not incur any liability for information provided by you to anyone
						which may result in your Account on the Platform being exposed or misused by any other person or any other
						harm or loss being suffered by you.
					</p>
					<p className='description'>
						Provided that Cashgrail or its employees have not been dishonest or negligent, we shall not be liable to a
						User for any unauthorized transaction which has been executed through the Platform as a result of:
					</p>
					<ol class='privacy-page-ol' type='a'>
						<li>
							fraud committed by the use of a User’s access credentials before the User has notified Cashgrail in
							writing of the loss or theft or such access credentials and the Cashgrail has been able to take actions to
							disable the access credentials;
						</li>
						<li>
							fraud committed by third parties against Users which may not be preventable by, or occur despite the
							security systems of the Platform;
						</li>
						<li>
							a voluntary action or transaction initiated suo motu by any User at the behest of another user, which is
							not initiated by Cashgrail for the transfer of funds, credit or stored value to another User.
						</li>
						<li>
							the security, accuracy, legality, appropriateness or any other aspect of the content or function of any
							third party’s products or services on our Platform.
						</li>
						<li>
							responsibility for the underlying transactions involving funds between you and the sender/ recipient of a
							payment, or the actions or identity of any transfer recipient or sender.
						</li>
					</ol>
					<p className='description'>
						You should routinely check the Account Balance section of your Account to ensure that there has been no
						unauthorised use of your Account. If you suspect any unauthorised activity you must contact our Grievance
						Officer.
					</p>
					<p className='description'>
						You should only transact with a third party product or service that you know. While the Platform may provide a
						link between the sender and the recipient of cash or credit balance(s), all payment transactions or any
						communications/offers are carried out through the Platform are solely between the sender and recipient of the
						payment and Cashgrail is not a party to and will not be responsible for any disputes, chargebacks or reversals
						arising pursuant to payment transactions solicited or initiated by and between Users.
					</p>
					<p className='description'>
						Please note that we can terminate or suspend your Account and all associated accounts, institute fraud
						prevention measures such as access restrictions, transaction velocity restrictions etc. without prior notice
						to prevent unauthorised use of our Accounts. You must co-operate with us in any investigation and use any
						fraud prevention measures we prescribe and you agree that we will not be liable to you or any third party for
						any block, suspension, cancellations, or termination of your Account owing to such measures.
					</p>
					<p className='description'>
						Please also contact us immediately if the device through which you access the Platform has been lost, stolen
						or compromised in any way or someone has used or may use your device or credentials without your authorisation
						or if you become aware of any unauthorised use of your Account.
					</p>
					<p className='description'>
						You are hereby informed that any and all information associated with any fraudulent transaction may be shared
						by Cashgrail (with or without demand) with the Government of India or any agency thereof, for the purpose of
						verification of the identity of User, or for prevention, detection, investigation, prosecution, and punishment
					</p>
					<h3 class='page-subheading'>G. User Conduct</h3>
					<p className='description'> In accessing or using our Platform you agree that you will not:</p>
					<ol class='privacy-page-ol' type='a'>
						<li>
							use any automated device, software, process or means to access, retrieve, scrape, or index our Platform or
							any content thereon without our express prior written consent;
						</li>
						<li>
							use any device, software, process or means to interfere or attempt to interfere with the proper working of
							our Platform;
						</li>
						<li>
							undertake any action that will impose a burden or make excessive traffic demands on our infrastructure
							that we deem, in our sole discretion, to be unreasonable or disproportionate site usage;
						</li>
						<li>
							use or index any content or data on our Platform for purposes of competing with us in any manner that we
							have not specifically authorised;
						</li>
						<li>
							transmit spam, chain letters, games, junk email, surveys, or other mass messaging, whether commercial in
							nature or not;
						</li>
						<li>
							use our Platform or any content therefrom in any manner which is, in our sole discretion, not reasonable
							and/or not for the purpose it is made available;
						</li>
						<li>
							violate the rights of any person, including copyright, trade secret, privacy right, or any other
							intellectual property or proprietary right;
						</li>
						<li>
							pose as any person or entity or attempt to solicit money, passwords or personal information from any
							person act in violation of any term or condition of using our Platform;
						</li>
						<li>
							reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or
							adaptations of, publicly display, sell, trade, or in any way exploit our Platform or any content thereon,
							except as expressly authorised by us; or
						</li>
						<li>
							transmit or attempt to transmit any computer viruses, worms, defects, Trojan horses or other items of a
							destructive nature.
						</li>
					</ol>
					<p className='description'>
						If you choose to submit any content by way of question on the Platform, you hereby warrant that no
						contribution or uploaded content shall:
					</p>
					<ol class='privacy-page-ol' type='a'>
						<li>
							be considered to be unlawful, threatening, abusive, bigoted, hateful, libellous, tortious, blasphemous,
							false or misleading, discriminatory, defamatory, obscene, vulgar, offensive, excessively violent, invasive
							of another privacy, publicity, contract or other rights, pornographic or inclusive of nudity, paedophilic,
							profane, sexually explicit, indecent, racially, ethnically objectionable, disparaging, relating or
							encouraging money laundering, or otherwise unlawful in any manner whatsoever;
						</li>
						<li>
							constitute, advocate or encourage conduct that would constitute or give rise to a criminal offence, civil
							liability or other violation of any local, state, national or international law;
						</li>
						<li> constitute or contain false or misleading indications of origin or statements of fact ; </li>
						<li>
							belongs to another person and to which you do not have any right to, or infringe the intellectual property
							rights (whether registered or unregistered) of Cashgrail or any third party
						</li>
						<li>
							contain any information, software or other material of a commercial nature or is patently false or untrue;
						</li>
						<li> contain advertising, promotions or commercial solicitations of any kind; </li>
						<li>harass or harm another person;</li>
						<li>exploit or endanger a minor;</li>
						<li>
							impersonate or attempt to impersonate any person or entity or mislead or attempt to mislead another person
							regarding your identity;
						</li>
						<li>
							threaten the unity, integrity, defence, security or sovereignty of the territory, friendly relations with
							foreign states, or public order or causes incitement to the commission of any cognizable offence or
							prevents investigation of any offence or is insulting any other nation.
						</li>
					</ol>
					<p className='description'>
						As it concerns the content uploaded by you, without prejudice to your obligation to otherwise comply with
						applicable laws during the course of using the Platform, you agree to hereby comply with any and all
						applicable laws, as well as any other rules and restrictions that may be set forth herein or on the Platform.
						Cashgrail may not be held responsible for any content contributed by Users on the Cashgrail Platform.
					</p>
					<p className='description'>
						The Users admit that they may be exposed to content posted by other Users which they may find offensive,
						obnoxious or indecent and that we may not be able to prevent such content from being posted. Such content
						should be brought to our notice. We shall act upon the information as we deem acceptable. The decision taken
						by the management of Cashgrail in this regard shall be final and binding on the User and you specifically
						agree that we shall not incur any liability under any circumstance whatsoever regarding the same.
					</p>
					<p className='description'>
						We reserve the right to prevent unauthorised access to or use of our Platform, including, but not limited to,
						instituting technological barriers, or reporting your conduct to any person or entity.
					</p>
					<p className='description'>
						In the event, we have reasonable grounds to believe that your activities include any of the acts specified
						above, we may initiate appropriate legal action against you as well as notify the relevant regulatory or law
						enforcement authorities where appropriate in addition to any other available remedies under law or equity,
						apart from restricting or suspending or terminating your use of the Platform.
					</p>
					<p className='description'>
						You shall not purchase, sell, assign, trade, rent, loan, lease, license, grant a security interest in, or
						transfer to your Account, any content, currency, points, standings, rankings, ratings, or any other attributes
						appearing in, originating from or associated with the Platform.
					</p>
					<p className='description'>
						Users agree to abide by these Terms and all other rules, regulations and terms of use of the Platform. In the
						event User does not abide by these Terms and all other rules, regulations and terms of use, Cashgrail may, at
						its sole and absolute discretion, take necessary remedial action, including but not limited to:
					</p>
					<ol class='privacy-page-ol' type='a'>
						<li> restricting, suspending, or terminating any User’s access to all or any part of Cashgrail Services; </li>
						<li>
							deactivating or deleting a User’s account and all related information and files on the account. Any amount
							remaining unused in the User’s Game Account or Winnings Account on the date of deactivation or deletion
							shall be transferred to the User’s bank account on record with Cashgrail subject to a processing fee (if
							any) applicable on such transfers as set out herein; or
						</li>
						<li>refraining from awarding any prize(s) or withholding access to the Platform by such infringing User.</li>
					</ol>
					<p className='description'>
						Users agree to ensure that they can receive all communication from Cashgrail by marking e-mails or sending
						SMSs from Cashgrail as part of their safe senders list. Cashgrail shall not be held liable if any e-mail/SMS
						remains unread by a User as a result of such e-mail getting delivered to the User’s junk or spam folder.
					</p>
					<p className='description'>
						If a User chooses a username or profile picture that, in Cashgrail’s considered opinion is obscene, indecent,
						abusive or that might subject Cashgrail to public disparagement or scorn, Cashgrail reserves the right,
						without prior notice to the User, to reset such username and intimate the User, or delete such username and
						posts from the Cashgrail Platform, deny such User access to the Cashgrail Platform, or any combination of
						these options.
					</p>
					<p className='description'>
						Unauthorized access to the Cashgrail Platform is a breach of these Terms, and a violation of the law. Users
						agree not to access the Cashgrail Platform by any means other than through the interface that is provided by
						Cashgrail for use in accessing the Cashgrail Platform. Users agree not to use any automated means, including,
						without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites,
						except those automated means that we have approved in advance and in writing.
					</p>
					<p className='description'>
						The use of the Cashgrail Platform is subject to existing laws and legal processes. Nothing contained in these
						Terms shall limit Cashgrail’s right to comply with governmental, court, and law-enforcement requests or
						requirements relating to Users use of the Cashgrail Platform.
					</p>
					<h3 class='page-subheading'>H. Payment Terms</h3>
					<p className='description'>
						In respect of any transactions entered into on the Cashgrail platform for playing game and contest(s), Users
						agree to be bound by the following payment terms to which Users shall be bound when participating in any game,
						and the User will pay the “<b>Contribution</b>” which will be inclusive of the following:
					</p>
					<ol class='privacy-page-ol' type='i'>
						<li>
							Pre-designated platform fee (“<b>Platform Fee</b>”) for access to Cashgrail Services. Such platform fee is
							inclusive of applicable GST; and
						</li>
						<li>
							Pre-determined contribution towards the prize money pool (“<b>Prize Money Pool</b>”) of such game/contest,
							which will be passed on to the winner(s) of the game/contest after the completion of the game/contest as
							per the terms and conditions thereto.
						</li>
					</ol>
					<p className='description'>
						Users by participating in any games on the Cashgrail Platform hereby authorize and appoint Cgames Customer
						Solutions LLP as their payment collection agent to collect and manage Users’ funds, for and on their behalf (
						<b>Agent</b>). For operational convenience, the Users’ directions (for collection and settlement of payments)
						will be communicated to the Agent through Cashgrail (which will transmit the Users’ instructions to the Agent
						“as is”).
					</p>
					<p className='description'>
						Cashgrail at its own discretion can also contribute to such Prize Money Pool from its own resources. Unless
						specifically identified in these terms, it is clarified that Cashgrail has no right or interest in this Prize
						Money Pool, and only acts as an intermediary engaged in collecting and distributing the Prize Money Pool in
						accordance with the game rules and these Terms.
					</p>
					<p className='description'>
						In respect of any transactions entered into on the Cashgrail Platform, including making a payment to
						participate in the games, the Users agree to be bound by the following payment terms: Subject to these Terms,
						all amounts collected from the User are held in separate non-interest earning bank account with an Agent. The
						said accounts are operated by the Agent (or a third party appointed by Cashgrail) in accordance with these
						Terms. From these bank accounts, the payouts can be made to (a) Users (towards their withdrawals of Winnings
						or Play Money); (b) Cashgrail (towards its Platform Fees); and (c) to the Government (towards TDS, if any, on
						amounts won). The Agent shall receive and hold the amounts of the Users in trust as a trustee of and for the
						benefit and use of the Users.
					</p>
					<p className='description'>
						Cashgrail receives only its share of the Platform Fees through the said Agent and has no control over the User
						funds held by the Agent in the dedicated non- interest earning escrow account. Cashgrail reserves the right to
						charge a Platform Fee, which would be specified and notified by it, prior to a User’s joining of a game. The
						Platform Fee (inclusive of applicable tax thereon) will be debited from the User account balance and Cashgrail
						shall issue an invoice for such debit on request to the User. The Platform Fee may be dynamic on a game to
						game basis and dependent on factors such as, number of Users, eligibility criteria, ranking and server load
						etc., subject to the Platform Fee being determined before the commencement of a game.
					</p>
					<p className='description'>
						The User may participate in a game wherein the User has to contribute a pre- specified contribution towards
						the Prize Money Pool of such game, which will be passed on to the Winner(s) of the game after the completion
						of the game as per the terms and conditions of such game. The amount to be paid-in by the User towards the
						Prize Money Pool would also be debited from the User Account balance maintained with the Agent.
					</p>
					<p className='description'>
						Any User availing of Cashgrail Services are provided with the following categories of accounts for the
						processing and reconciliation of payments:
					</p>
					<ol class='snl-list'>
						<li>
							<b>Play Money</b>, which comprises of money contributed by the User through a payment gateway towards
							Prize Money Pool, after disbursement of the Platform Fee to Cashgrail (“<b>Play Money</b>”);
						</li>
						<li>
							<b>Winnings</b> which comprise the money won by the Users by winning the game/contest(s) (“<b>Winnings</b>
							”);
						</li>
						<li>
							<b>Bonus Money</b>, which comprises bonus points given by Cashgrail, at its sole discretion towards
							marketing and promotion. The Bonus Money itself cannot be withdrawn (“<b>Bonus Money</b>”);
						</li>
						<li>
							<b>Cashbacks</b> comprises of such amounts of money provided by Cashgrail upon deposit of Play Money in
							the Wallet. Cashbacks may only be used for the Services, and cannot be withdrawn unless used and converted
							to Winnings on the Platform (“<b>Cashbacks</b>”).
						</li>
					</ol>
					<p className='description'>
						Each time a User participates in any game on the Cashgrail Platform, the Contribution shall be debited from
						the Users Account. The Contribution for each User shall be first debited from the Play Money and thereafter,
						any remaining amount of such Contribution shall be debited from the Winnings, if any.
					</p>
					<p className='description'>
						In case any amount is remaining to be paid by the User as part of the Contribution in relation to such Users
						participation in any game(s), the User will be taken to the designated payment gateway to give effect to such
						payment which shall also be credited to the account maintained by the Agent. In case any amount added by the
						User through such payment gateway exceeds the remaining amount of the Contribution, the amount in excess shall
						be transferred to the Play Money and will be available for use in participation in the game(s) or for
						withdrawal in accordance with these Terms.
					</p>
					<p className='description'>
						Debits from the Play Money for the purpose of enabling a User’s participation in a game shall be made in order
						of the date of credit of amounts in the Play Money, and accordingly, amounts credited into Play Money earlier
						in time shall be debited first.
					</p>
					<p className='description'>
						In order to request a withdrawal of any amount standing in the Play Money, the User must first complete
						Cashgrail’s identity verification, and bank account verification, which will also require submission of User’s
						PAN card and bank account details and by providing a reason for withdrawal over email nodal@zupee.in.
						Cashgrail shall effect an online transfer to the User’s bank account on record with Cashgrail by instructing
						the Agent on behalf of the User (to which the User expressly consents), within a commercially reasonable
						period of time. Such transfer will reflect as a debit to the User’s Play Money. The withdrawal requests by
						Users on the Platform, when requested by Users, shall only be limited to Play Money i.e. the money added by
						the Users on the Platform and not any Bonus Money or Cashbacks provided by Cashgrail. Cashgrail may, in
						certain exceptional circumstances and at its sole and absolute discretion, deduct applicable cancellation
						charges, Cashbacks received, Bonus Money, and taxes including GST, from the amount to be refunded to the User.
					</p>
					<p className='description'>
						Further, withdrawal of any amount standing to the User’s credit in the Winnings may be made by way of a
						request to the Agent through Cashgrail. In such case, Cashgrail shall cause an online transfer to the User’s
						bank account on record with Cashgrail by instructing the Agent on behalf of the User (to which the User
						expressly consents), within a commercially reasonable period of time. Such transfer will reflect as a debit to
						the User’s Winnings. Cashgrail shall not charge any processing fee for causing the online transfer of such
						amount from the Winnings to the User’s bank account on record with Cashgrail
					</p>
					<p className='description'>
						Members need to complete the verification process with Cashgrail or its Agent, in order to raise the
						withdrawal request. As a general practice, Prize Money winners be required (as and when prescribed by
						Cashgrail) to verify their e- mail address &amp; phone number, along with the following documents:
					</p>
					<ol class='privacy-page-ol' type='a'>
						<li>A copy of the Member’s PAN card;</li>
						<li>Member’s bank account details and proof of the same.</li>
					</ol>
					<p className='description'>
						Cashgrail shall not instruct the Agent to provide for the withdrawal of a User’s prize(s)/accumulated
						Winnings/Play Money unless the above-mentioned documents have been received and verified within the time-
						period stipulated by Cashgrail. Only to those winners who successfully complete the verification process and
						provide the required documents within the time limit specified by Cashgrail shall the Agent provide for (at
						the instructions of Cashgrail on behalf of the User, to which the User expressly consents) withdrawal/receipt
						of their accumulated Winnings/Play Money (or any part thereof).
					</p>
					<p className='description'>
						Users will be required to provide valid photo identification and address proof documents for proof of identity
						and address in order for Cashgrail to process the withdrawal request. The name mentioned on the User’s photo
						identification document should correspond with the name provided by the User at the time of the KYC check
						being conducted by Cashgrail or its Agent, as well as the name and address existing in the records of the
						User’s bank account as provided to Cashgrail or its Agent. In the event that no bank account has been
						registered by the User against such User’s account with Cashgrail, or the User has not verified his/her User
						account with Cashgrail, to Cashgrail’s satisfaction and in accordance with these Terms, Cashgrail shall
						provide such User with a notification to the User within the app or via SMS by Cashgrail and the User shall
						register a bank account with his/her User Account and/or to verify his/her User Account to proceed with
						withdrawal requests.
					</p>
					<p className='description'>
						Cashgrail shall scrutinise all documents submitted and may, at its sole and absolute discretion, disqualify
						any winner from withdrawing his accumulated Winnings (or any part thereof) on the following grounds:
					</p>
					<ol class='snl-list'>
						<li>
							Determination by Cashgrail that any document or information submitted by the Member is incorrect,
							misleading, false, fabricated, incomplete or illegible; or
						</li>
						<li>Member does not fulfil the Eligibility Criteria; or</li>
						<li>Any other ground as reasonably determined by Cashgrail.</li>
					</ol>
					<p className='description'>
						The User represents and warrants that the documents provided in the course of the verification process are
						true copies of the original documents to which they relate.
					</p>
					<p className='description'>
						Users are required to provide proper and complete details at the time of registration. Cashgrail shall not be
						responsible for communications errors, commissions or omissions including those of the Users due to which the
						results may not be communicated to the Winner.
					</p>
					<p className='description'>
						Where any of the fixtures within a game are cancelled or abandoned without an official result, all game
						entries are considered void and the Contribution shall be duly refunded to User. There are no prize/winnings
						pay-outs and Platform Fee charges for these voided games.
					</p>
					<p className='description'>
						If, in a transaction performed by you on the Platform, money has been charged to your card or bank account and
						respective amount is not added in your Cashgrail Account within 24 hours of the completion of the transaction,
						then you shall inform us by sending an e-mail to
						<a class='sub-page-link' href='mailto:complaints@zupee.in'>
							complaints@zupee.in
						</a>
						. Please include the following details in the e-mail: your mobile number, transaction value, transaction date
						and transaction ID. Cashgrail will investigate the incident and, if it is found that money was indeed charged
						to your card or bank account without delivery of the balance in your Cashgrail Account, then you will be
						refunded the money within 7 business days from the date of receipt of your e-mail. All refunds will be
						credited to your Cashgrail Account.
					</p>
					<p className='description'>
						Cashgrail reserves the right to withdraw and/or cancel any game(s) to be conducted or already conducted,
						without prior notice to any Users or winners of any game(s). In such an event, Cashgrail shall refund the
						amount of Contribution contributed by each participant. Further, Cashgrail reserves a right to recover the
						Prize Money won by the Participants, in case of termination of the game(s) which have already been
						conducted/concluded where there has been any technical errors, nullification of the results due to player
						manipulation, collusive or fraudulent gameplay, or incorrect declaration of winnings.
					</p>
					<h3 class='page-subheading'>I. WALLET FUNDS</h3>
					<p className='description'>
						The User hereby consents to the transfer of his/her wallet balance from the games offered on other Cashgrail
						games on apps/websites to the Platform. We are liable to refund only the amounts you add to the wallet which
						are left unused in the wallet.
					</p>
					<p className='description'>
						The Users agree and acknowledge that should the User Account remain inactive for a period of 12 months, the
						wallet balances shall expire, and after such period as statutorily permitted, Cashgrail will forfeit such
						amount in the User’s wallet and shall use such amounts, inter alia for spreading awareness on responsible
						online game play. Cashgrail will periodically notify the User of the existence of such unused balance and the
						forfeiture thereof 45 days prior to the date of expiry of the wallet amount by way of SMS, email, or call. The
						User agrees that if the User does not use the Services or utilize the amount in the wallet, or request for
						refund (where applicable), during such notice period then he/she consents to the forfeiture of the amount in
						the wallet.
					</p>
					<p className='description'>
						If it comes to the knowledge of Cashgrail that a User is operating multiple accounts, any balance in such
						User’s accounts is liable to be forfeited by Cashgrail in addition to the suspension/termination of such
						accounts.
					</p>
					<h3 class='page-subheading'>J. Selection and Verification of Winners and Conditions relating to the Prizes</h3>
					<p className='description'>
						Winners will be decided on the basis of the scores of the players in a designated round of a game(s). The
						Users with the highest aggregate score in a particular round shall be declared the Winner(s). In certain
						pre-specified games, Cashgrail may declare more than one Winner and distribute prizes to such Winners in
						increasing order of their aggregate score at the end of the designated round of the game. The contemplated
						number of Winners and the prize due to each Winner in such game shall be as specified on the game page prior
						to the commencement of the game.
					</p>
					<p className='description'>As a general practice, winners may be required to provide following documents:</p>
					<ol class='privacy-page-ol' type='a'>
						<li>A copy of the User’s PAN card;</li>
						<li>Photocopy of a government-issued residence proof;</li>
						<li>User’s bank account details and proof of the same.</li>
					</ol>
					<p className='description'>
						Cashgrail shall not permit a Winner to withdraw his/her prize(s)/accumulated winnings unless the
						above-mentioned documents have been received and verified within the time-period stipulated by Cashgrail, and
						the process prescribed in these Terms has been duly followed.
					</p>
					<p className='description'>
						The User represents and warrants that the documents provided in the course of the verification process are
						true copies of the original documents to which they relate.
					</p>
					<h3 class='page-subheading'>K. Taxes Payable</h3>
					<p className='description'>
						All winnings from a game which are in excess of INR 10,000/-, shall be subject to deduction of tax at source
						(‘TDS’) under section 194B of the Income Tax Act 1961 (‘the Act’) at the rate of 30% (excluding surcharge and
						cess). The winnings shall be the difference between Prize Money Amount won in a game less the Contribution
						paid by the User. This tax shall be deducted before the winnings are credited into the game winnings wallet.
						In case of any revisions by the Government of India to the aforementioned rate in the future, TDS will be
						deducted by Cashgrail in accordance with the then current prescribed TDS rate. Winners will be provided TDS
						certificates in respect of such tax deductions. The Winners shall be responsible for payment of any other
						applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the Prize Money.
					</p>
					<p className='description'>
						The decision of Cashgrail with respect to the awarding of prizes shall be final, binding and non-contestable.
					</p>
					<p className='description'>
						To the extent permitted by law, Cashgrail makes no representations or warranties as to the quality,
						suitability or merchantability of any prizes and shall not be liable in respect of the same.
					</p>
					<p className='description'>
						Cashgrail may, at its sole and absolute discretion, vary or modify the prizes being offered to winners. Users
						shall not raise any claim against Cashgrail or question its right to modify such prizes being offered, prior
						and after the closure of the game.
					</p>
					<p className='description'>
						The Winners shall bear all transaction charges levied for delivery of cash prizes. All prizes are
						non-transferable.
					</p>
					<h3 class='page-subheading'>L. Prevention of Collusion and Fraud</h3>
					<p className='description'>Cashgrail is committed to promoting fair play on our Platform.</p>
					<p className='description'>
						Our compliance team will track game play of all Users and strict action will be taken against any User(s)
						caught colluding or indulging in unfair game practices to cause undue advantage or undue disadvantage to any
						other User. By agreeing to these Terms, you agree not to indulge in any unfair game practice that may provide
						undue advantage to you or any other User and contravention of our
						<a class='sub-page-link' href='/responsible-gaming/'>
							Responsible Gaming
						</a>
						and
						<a class='sub-page-link' href='/fair-play/'>
							Fair Play Policy
						</a>
						.
					</p>
					<p className='description'>
						We reserve the right to take any necessary action, as it deems fit, against Users found indulging in unfair
						means. The action could include, inter alia, monetary penalties, temporary account suspension, redemption
						blocking and/or permanent account deactivation.
					</p>
					<p className='description'>
						Depositing and withdrawing without playing any or a reasonable amount of game(s) is a violation of our fair
						play deposit and withdrawal policy. Any User found committing such an action is in violation of our fair play
						deposit and withdrawal policy and further to investigation is subject to a penalty fee.
					</p>
					<p className='description'>
						Any User will be deemed to be a fraud User for any reason, for example, found depositing or withdrawing
						from/to a fraudulent account or account not belonging to the User and/or account/card belonging to someone
						else without such cardholder’s consent, is subject to legal consequences including but not limited to
						withholding or stopping any withdrawal or use of the User’s Account Balance and blocking of the User’s
						Account.
					</p>
					<h3 class='page-subheading'>M. Intellectual Property</h3>
					<p className='description'>
						The Cashgrail Platform includes a combination of content created by Cashgrail, its partners, affiliates,
						licensors, associates and/or Users. The intellectual property rights (<b>Intellectual Property Rights</b>) in
						all software underlying Cashgrail and the Cashgrail Platform and material published on the Cashgrail Platform,
						including (but not limited to) games, games’ software, advertisements, written content, photographs, graphics,
						images, illustrations, marks, logos, audio or video clippings and flash animation, is owned by Cashgrail, its
						affiliates, partners, licensors and/or associates. Users may not modify, publish, store, transmit, participate
						in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly
						display, or in any way exploit or use any of the materials or content on the Cashgrail Platform either in
						whole or in part without express written license from Cashgrail. Nothing in this Agreement shall be construed
						to convey, and the Users by virtue of this Agreement shall not acquire, any ownership or other interest or
						right in any intellectual property any of the materials or content on the Cashgrail Platform.
					</p>
					<p className='description'>
						Users may request permission to use any Cashgrail content by writing in to Cashgrail Helpdesk.
					</p>
					<p className='description'>
						Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they
						upload, post, e-mail, transmit, or otherwise make available on Cashgrail (Users’ Content). Each User
						represents and warrants that he/she owns all Intellectual Property Rights in the User’s Content and that no
						part of the User’s Content infringes any third-party rights. Users further confirm and undertake to not
						display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary
						rights of any third party on Cashgrail. Users agree to indemnify and hold harmless Cashgrail, its directors,
						employees, affiliates and assigns against all costs, damages, loss and harm including towards litigation costs
						and counsel fees, in respect of any third party claims that may be initiated including for infringement of
						Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels,
						trademarks, copyrights or intellectual and proprietary rights on Cashgrail, by such User or through the User’s
						commissions or omissions. We reserve the right to assume the exclusive defence and control of any matter
						otherwise subject to indemnification by you, in which event you will assist and cooperate with Cashgrail in
						asserting any available defences.
					</p>
					<p className='description'>
						Users hereby grant to Cashgrail and its affiliates, partners, licensors and associates a worldwide,
						irrevocable, perpetual, royalty-free, non-exclusive, sub-licensable license to use, store, reproduce, create
						derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users’
						Content for any of the following purposes:
					</p>
					<ol class='privacy-page-ol' type='a'>
						<li>displaying Users’ Content on Cashgrail</li>
						<li>
							distributing Users’ Content, either electronically or via other media, to other Users seeking to download
							or otherwise acquire it, and/or
						</li>
						<li>storing Users’ Content in a remote database accessible by end Users, for a charge</li>
						<li>
							This license shall apply to the distribution and the storage of Users’ Content in any form, medium, or
							technology. The license shall not lapse due to non-use by Cashgrail whether under any provision of the
							Copyright Act, 1957 or any other law.
						</li>
					</ol>
					<p className='description'>
						All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Cashgrail
						belonging to any person (including User), entity or third party are recognized as proprietary to the
						respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks,
						copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under
						notice to Cashgrail.
					</p>
					<h3 class='page-subheading'>N. Testimonial Terms</h3>
					<p className='description'>
						Cashgrail may capture and obtain visual and/or audio recordings or performances, still images of the User, and
						text provided by the User for the testimonial being provided towards the games (the results of which are the
						<b>Recordings</b>). The Recordings provided by the User are voluntary and Cashgrail is the sole and exclusive
						owner of all rights in and to the Recordings, and all elements thereof (including, without limitation, the
						copyright thereto). Without prejudice to the above, the user grants to Cashgrail a perpetual royalty free
						license, to publicly use, distribute, reproduce, create derivative works from, and perform/display the the
						user’s name, voice, likeness, appearance, biographical information and other indicia of its identity as
						included in the Recordings and any excerpts or version thereof for their marketing, in any language, and
						without any geographical limitation. Cashgrail may incorporate to the recordings and any separate content
						(e.g., quotes, photos, videos, artwork, materials, etc.) to market their platform and services. The user also
						hereby waives any right to inspect or approve the finished matter based on the Recording that may be used now
						or in the future, whether that use is known to the user or unknown.
					</p>
					<p className='description'>
						Cashgrail is not obligated to use the Recordings if it so wishes. The User will not attempt to enjoin or
						otherwise impair Cashgrail’s use of the Recordings that is in accordance with this Release.
					</p>
					<h3 class='page-subheading'>O. Third Party Sites, Services and Products</h3>
					<p className='description'>
						Cashgrail may contain links to other Internet sites owned and operated by third parties. Users’ use of each of
						those sites is subject to the conditions, if any, posted by the sites. Cashgrail does not exercise control
						over any Internet sites apart from Cashgrail and cannot be held responsible for any content residing in any
						third-party Internet site. Cashgrail’s inclusion of third-party content or links to third-party Internet sites
						is not an endorsement by Cashgrail of such third-party Internet site.
					</p>
					<p className='description'>
						Users’ correspondence, transactions/offers or related activities with third parties, including payment
						providers and verification service providers, are solely between the User and that third party. Users’
						correspondence, transactions and usage of the services/offers of such third party shall be subject to the
						terms and conditions, policies and other service terms adopted/implemented by such third party, and the User
						shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of
						such third party. User agrees that Cashgrail will not be responsible or liable for any loss or damage of any
						sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or
						claims related to any third-party product or service should be directed to the appropriate vendor.
					</p>
					<p className='description'>
						Cashgrail Platform contains content that is created by Cashgrail as well as content provided by third parties.
						Cashgrail does not guarantee the accuracy, integrity, quality of the content provided by third parties and
						such content may not relied upon by the Users in utilizing the Cashgrail Services provided on the Cashgrail
						Platform including while participating in any of the games hosted on the Cashgrail Platform.
					</p>
					<h3 class='page-subheading'>P. Privacy</h3>
					<p className='description'>
						By using the Platform and providing any of your personal information to the Platform, You affirmatively
						consent and agree to comply with our Privacy Policy, guidelines and statements as may be applicable from time
						to time, which are incorporated into and form an integral part of these Terms.
					</p>
					<h3 class='page-subheading'>Q. Disconnection and Technical Issues</h3>
					<p className='description'>
						You understand and acknowledge that once a game has commenced, not being able to play due to slow internet
						connections, faulty hardware, technical failure due to customer’s hardware, internet connection failure, low
						computer configuration or for any other reason not attributable to us does not require us to a refund of the
						participation amount you may have paid for participation.
					</p>
					<p className='description'>
						In case of any technical failures, server crashes, breakdowns, software defects, disruption or malfunction of
						service at our end, as a policy, we will cancel the game(s) and refund the participation amounts after proper
						verification and no service fee will be charged for such games and you accept that we are not responsible to
						you in all such cases. For any game, we have the right to cancel and refund the participation amount. In no
						case, other than a server crash, are we accountable for any of the Users’ disconnections from server. We are
						also not liable for any prospective winnings from any incomplete game.
					</p>
					<p className='description'>
						We do not hold any liability to any disconnection, lag, freeze or interference in the network on the User’s
						computer or any other external networks.
					</p>
					<h3 class='page-subheading'>R. Disclaimers</h3>
					<p className='description'>
						By registering, you are considered to have accepted and understood all the Terms. Our failure or delay to act
						or exercise any right or remedy with respect to a breach of any of these Terms by you does not amount to
						surrendering or waiving our rights to act with respect to any prior, concurrent, subsequent or similar
						breaches.
					</p>
					<p className='description'>
						If any judicial or quasi-judicial body in India declares any of the provisions of these Terms to be unlawful,
						invalid, void or unenforceable for any reason, the validity and enforceability of the remaining provisions
						will not be affected. Any such inappropriate term or condition will be replaced with another term or condition
						that is valid and enforceable and is most nearly of similar effect as the original invalid term. In such
						event, the parties shall be obligated to construe and interpret the void, illegal, or unenforceable provision
						with a valid, legal, and enforceable provision in such manner that corresponds as closely as possible to
						Cashgrail’s intent and objective in the void, illegal, or unenforceable provision.
					</p>
					<h3 class='page-subheading'>S. Warranty</h3>
					<p className='description'>
						Cashgrail disclaims any and all warranties, expressed or implied, in connection with the underlying software
						and the merchantability and utility thereof of the Platform, and the game(s), all of which are provided to the
						User as is. Your access and use of the Platform is at your sole risk. Cashgrail explicitly disclaims all
						warranties or conditions of any kind, express, implied or statutory, including without limitation the implied
						warranties of title, non-infringement, merchantability, completeness, availability, security, compatibility
						and fitness for a particular purpose.
					</p>
					<p className='description'>Without limiting the foregoing, Cashgrail does not represent or warrant:</p>
					<ol class='privacy-page-ol' type='a'>
						<li>
							The quality, fitness for purpose, completeness, merchantability, non- infringement or accuracy of the
							software, the Platform and the game(s);
						</li>
						<li>
							The suitability of the information contained in the documents or other information published on the
							Platform;
						</li>
						<li>
							Continuous, error-free, secure or virus-free operation of the Platform or its content including software,
							game(s), your Account or continued operation or availability of any facility on the Platform;
						</li>
						<li> That the defects in the Platform will be corrected; </li>
						<li>
							That the Platform or the servers that operate the Platform are free of viruses or other harmful
							components;
						</li>
						<li>
							That the data, results and information within the Platform will be correct, accurate, adequate, useful,
							reliable, correctly sequenced, timely or otherwise; and
						</li>
						<li> That the Platform will meet your needs, requirements or expectations. </li>
					</ol>
					<p className='description'>
						No person affiliated, or claiming affiliation with the Platform has authority to extend such warranties.
					</p>
					<p className='description'>
						Without limiting the generality of the foregoing, to the maximum extent permitted by applicable law, we shall
						have no liability for any damages or injury caused, in whole or in part, by contingencies or issues beyond our
						reasonable control, including, but not limited to: the acts of third parties, errors in the content or the
						Platform, network failures, internet failures, software and hardware failures, viruses and other system
						attacks, labour stoppages, riots, acts of government or god, natural disasters, acts of terrorism,
						communication line failure, or theft, destruction of, unauthorized access to, alteration of or use of records.
					</p>
					<p className='description'>
						To the maximum extent permitted by applicable law, you hereby agree that you shall have no remedy in respect
						of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not
						set out in these Terms. You agree that you shall have no claim for innocent or negligent misrepresentation or
						negligent misstatement based on any statement in these Terms.
					</p>
					<p className='description'>
						The foregoing limitations shall apply regardless of whether (i) liability or damage is alleged for breach of
						contract, tortious behaviour, negligence, or under any other theory or cause of action, and/or (ii) the party
						against which liability or damages is sought was advised of the possibility thereof. For the avoidance of
						doubt, nothing in these Terms shall limit or exclude liability for (i) death or personal injury caused by
						negligence; or (ii) fraud.
					</p>
					<p className='description'>
						To the maximum extent permitted by applicable law, you agree that regardless of any statute or law to the
						contrary, any claim or cause of action arising out of or related to these Terms, the Platform, or any part
						thereof, must be asserted within one (1) year after such claim or cause of action arose, or it shall be
						forever barred.
					</p>
					<p className='description'>
						You agree and acknowledge that Cashgrail does not promise or ensure that you will be able to access your
						Account whenever you want. It is entirely possible that you may not be able to access your Account or the
						Platform provided by Cashgrail at times or for extended periods of time due to, but not limited to system
						maintenance and updates.
					</p>
					<p className='description'>
						You agree and acknowledge that participation in the games of skill organized by us may result in financial
						loss to you. With full knowledge of the facts and circumstances surrounding the game(s), you are voluntarily
						participating in the game(s) and assume all responsibility for it and the risk resulting from your
						participation, including all risks of financial loss. Cashgrail makes no guarantees, warranties,
						representations, or other promises relating to the game(s), and assumes no liability or responsibility for any
						financial loss that you may sustain as a result of participation in the game(s). You will be solely
						responsible for any delay and/or damage to your computer systems or loss of data that results from the
						installation of any software on your device and we expressly disclaim liability for any such failure delay or
						failure.
					</p>
					<p className='description'>
						You agree to assume the entire risk as to the results and performance of any software and/or game(s) availed
						by you on the Platform. As such, result and performance among other things depends on your Internet connection
						and hardware. You also assume the entire cost of all servicing, repair and/or correction of your hardware when
						you access, download or execute software or game(s) available on the Platform. We explicitly disclaim
						liability for any delay or failure to perform resulting from installation of any software on your computer.
					</p>
					<p className='description'>
						You specifically acknowledge, agree and accept that we are not liable to you for: (i) the defamatory,
						offensive or illegal conduct of any other User or for anything that turns out to be misleading, inaccurate,
						defamatory, threatening, obscene or otherwise illegal whether originating from another User or otherwise; (ii)
						any loss whatsoever arising from the use, abuse or misuse of your Account on our Platform; (iii) any loss
						incurred in transmitting information from you to our Platform by the Internet or by other connecting media;
						(iv) any technical failures, system breakdowns, defects, delays, interruptions, manipulated or improper data
						transmission, loss or corruption of data or communication lines failure, distributed denial of service
						attacks, viruses or any other adverse technological occurrences arising in connection with your access to or
						use of our Platform; (v) the accuracy, completeness or currency of any information services provided or any
						statistics shown on the Platform.
					</p>
					<h3 class='page-subheading'>T. Indemnification and Limitation of Liability</h3>
					<p className='description'>
						You clearly understand and agree that Cashgrail shall under no circumstances (including, without limitation,
						in contract, negligence or other tort), be liable for any direct, indirect, ancillary, special, incidental,
						consequential or exemplary or punitive damages. This includes, but is not limited to injury, claim, loss of
						data, loss of income, loss of profit or loss of opportunity, loss of or damage to property, damages for
						monetary loss, goodwill, use, data or other intangible loss (even if Cashgrail has been advised of the
						possibility of such damages), resulting from or arising out of the use of or the inability to use the
						Platform, even if we have been advised or become aware of the possibility of such damages or loss or that such
						loss was foreseeable.
					</p>
					<p className='description'>
						You agree to indemnify us and each of our respective general and limited partners, Users, shareholders,
						directors, officers, employees, agents, representatives, vendors and business partners harmless from and
						against any and all claims, liabilities, obligations, losses, costs or debt, damages and expenses (including
						attorneys’ fees and court costs) arising out of or relating to:
					</p>
					<ol class='snl-list'>
						<li>
							breach of these Terms(including negligence)or third party claims or liabilities arising against Cashgrail
							arising out of such a breach, based in contract, tort, statute, fraud, misrepresentation, or any other
							legal theory, and regardless of whether a claim arises during or after the termination of these Terms your
							use of the Platform in any manner that is contrary to Applicable Laws, with an intent to deceive, defraud,
							cheat, mislead or solicit any business, monetary or non-monetary consideration or information from another
							User;
						</li>
						<li>
							your use of the Platform, including, but not limited to your posting, use of, modification or interaction
							with of any content;
						</li>
						<li>
							your breach of any applicable laws or governmental or judicial order which applies to your use of the
							Platform from a specific geography;
						</li>
						<li>
							any unauthorized, improper, illegal or wrongful use of your Account by any person, including a third
							party, whether or authorized or permitted by you;
						</li>
						<li> your content; </li>
						<li>
							use by any other person accessing the Platform using your username or password, whether or not with your
							authorization,
						</li>
						<li> the use by us of information provided by you through our Platform. </li>
					</ol>
					<p className='description'>
						This indemnification obligation will survive the expiry or termination of these Terms and your use of the
						Platform.
					</p>
					<p className='description'>
						There could be instances where Cashgrail is not able to receive prompt updates about the change in applicable
						laws of your place of stay. It is your obligation to ensure that any activities you engage on the Platform are
						legal as per the applicable laws. You agree to indemnify and hold harmless, Cashgrail or its Platform, from
						any claim, demand, damage, or loss whatsoever coming out due to your non-compliance with the laws of your
						jurisdiction.
					</p>
					<h3 class='page-subheading'>U. Termination</h3>
					<p className='description'>
						We may change, suspend or discontinue any aspect of the Platform at any time, including the availability of
						any Platform feature, database, or content. We may also impose limits on certain features, promotions, and
						services or restrict your access to parts of the Platform, without notice or liability at any time in our
						exclusive discretion, without prejudice to any legal or equitable remedies available to us, for any reason or
						purpose. However, under normal circumstances, we will only do so where there has been conduct that we believe
						violates these Terms or other rules and regulations or guidelines posted on the Platform or conduct which we
						believe is harmful to other Users, to our businesses, or other information providers.
					</p>
					<p className='description'>
						Upon any termination of this agreement, you shall immediately discontinue your use and access to the Platform
						and destroy all materials obtained from it. You hereby agree and consent to the above and agree and
						acknowledge that we can, at our sole discretion, exercise our right in relation to any or all of the above,
						and that we shall not be liable in any manner of the same; and you agree, acknowledge and consent to the same.
					</p>
					<p className='description'>
						You acknowledge that your representations, undertakings, and warranties and the clauses relating to
						indemnities, limitation of liability, grant of license, governing law and jurisdiction, confidentiality shall
						survive the efflux of time and the termination of these Terms.
					</p>
					<h3 class='page-subheading'>V. Agreement with Agent</h3>
					<p className='description'>
						Cashgrail shall enter into a separate agreement with the Agent for regulating their inter-se contractual
						obligations to give effect to these Terms from time to time, as it considers appropriate and necessary in its
						sole discretion.
					</p>
					<h3 class='page-subheading'>W. Publicity</h3>
					<p className='description'>
						Acceptance of a prize by the Winner constitutes permission for Cashgrail, and its affiliates to use the
						Winner’s name, likeness, voice and comments for advertising and promotional purposes in any media worldwide
						for purposes of advertising and trade without any further permissions or consents and/or additional
						compensation whatsoever. The Winners further undertake that they will be available for promotional purposes as
						planned and desired by Cashgrail without any charge. The exact dates remain the sole discretion of Cashgrail.
						Promotional activities may include but not be limited to press events, internal meetings and
						ceremonies/functions.
					</p>
					<h3 class='page-subheading'>X. Publicity</h3>
					<p className='description'>
						In case You have any grievance with respect to the Platform or the Cashgrail Services, You can send an email
						to Mr Avijit Sharma at nodal@zupee.in (hereinafter referred to as “<b>Nodal Officer</b>” and “
						<b>Grievance Officer</b>“). We reserve the right to replace the Nodal Officer at any time without any notice
						or intimation to You. You can directly escalate to our management team by contacting our Nodal Officer. Any
						queries raised by Users will be acknowledged within 48 hours and a ticket will be raised. Cashgrail will
						redress the compliant as soon as possible and in any case within 30 days.
					</p>

					<p className='description'>
						Subject to the clauses below, the courts of competent jurisdiction at Haryana shall have exclusive
						jurisdiction to determine any and all disputes arising out of, or in connection with the Platform provided by
						Cashgrail (including the Game(s)), the construction, validity, interpretation and enforceability of these
						Terms, or the rights and obligations of the Users), as well as the exclusive jurisdiction to grant interim or
						preliminary relief in case of any dispute referred to arbitration as given below. All such issues and
						questions shall be governed and construed in accordance with the laws of the Republic of India.
					</p>

					<p className='description'>
						In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising
						the dispute shall provide a written notification (<b>Notification</b>) to the other party. On receipt of
						Notification, the parties shall first try to resolve the dispute through discussions. In the event that the
						parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute
						shall be settled by confidential arbitration.
					</p>

					<p className='description'>
						The place of arbitration shall be Haryana, India. All arbitration proceedings shall be conducted in English
						and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to
						time. The parties shall mutually appoint a sole arbitrator.
					</p>

					<p className='description'>
						The arbitration award will be final and binding on the parties, and each party will bear its own costs of
						arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise.
					</p>

					<p className='description'>
						The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific
						performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned
						award.
					</p>

					<p className='description'>
						Nothing contained in these Terms shall prevent Cashgrail from seeking and obtaining interim or permanent
						equitable or injunctive relief, or any other relief available to safeguard its interest prior to, during or
						following the filing of arbitration proceedings or pending the execution of a decision or award in connection
						with any arbitration proceedings from any courts of competent jurisdiction at Haryana to grant the same. The
						pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Cashgrail to pursue any
						remedy for monetary damages through the arbitration described herein.
					</p>

					<p className='description'>
						By agreeing to these terms, you agree to (i)
						<a class='sub-page-link' href='/terms-of-service-terms/'>
							these terms
						</a>
						, (ii) the
						<a class='sub-page-link' href='/privacy-policy/'>
							privacy policy
						</a>
						and any other policy or terms provided by Cashgrail.
					</p>
				</div>
			</CommonPageRenderer>
		</div>
	);
};

export default TermsOfServiceTerms;
